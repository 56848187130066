<template>
  <div class="home">
    <!-- <y-header>
      <van-icon name="search" @click="dispatchSearch" />
    </y-header> -->
    <side-menu></side-menu>
    <router-view ref="child" />
  </div>
</template>

<script>
// @ is an alias to /src
import YHeader from "@/components/YHeader.vue";
import SideMenu from "@/components/SideMenu.vue";

export default {
  name: "Home",
  data() {
    return {};
  },
  methods: {
    closeSideMenu() {
      this.$store.commit("setMenuState", false);
    },
    dispatchSearch() {
      this.$refs.child.showSearchPanel();
    },
  },
  components: {
    YHeader,
    SideMenu,
  },
};
</script>
<style lang="scss" scoped>
</style>