import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuState: false, // 菜单
    isClickHeader: false, //是否点击头部显示菜单
  },
  getters: {
    getMenuState(state) {
      return state.menuState
    },
    getIsClickHeader(state) {
      return state.isClickHeader
    }
  },
  mutations: {
    setMenuState(state, data) {
      state.menuState = data;
    },
    setIsClickHeader(state, data) {
      state.isClickHeader = data;
    }
  },
  actions: {
  },
  modules: {
  }
})
