<template>
  <div class="nav-bar">
    <van-col span="4" style="text-align: left"
      ><van-icon name="bars" @click="showSideMenu"
    /></van-col>
    <van-col
      ><span>{{ $route.meta.title || "财务管理系统" }}</span></van-col
    >
    <van-col span="4" style="text-align: left">
      <slot style="text-align: left"></slot>
    </van-col>
  </div>
</template>
<style lang="scss" scoped>
</style>
<script>
export default {
  data() {
    return {
      searchName: "",
    };
  },
  computed: {
    menuState() {
      return this.$store.getters.getMenuState;
    },
  },
  mounted() {},
  methods: {
    showSideMenu() {
      this.$store.commit("setIsClickHeader", true);
      this.$store.commit("setMenuState", !this.menuState);
    },
    showSearchPanel() {
      console.log("showSearchPanel");
    },
    onConfirm() {
      this.$refs.searchPanel.toggle();
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-bar {
  background-color: #586cb1;
  color: #fff;
  position: sticky;
  top: 0;
  height: 44px;
  line-height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  padding: 0 15px;
  z-index: 999999;
}
</style>