<template>
  <van-popup
    v-model="menuShow"
    position="left"
    :overlay="false"
    overlay-class="side-menu-overlay"
    class="side-menu"
    ref="sideMenu"
  >
    <div class="side-menu-top">
      <div class="user-info">
        <van-image :src="require('../assets/default-avator.png')" />
        <span>{{phone}}</span>
      </div>
      <div class="menu-title">
        索道财务系统
        <van-divider />
      </div>
    </div>
    <van-sidebar v-model="activeKey">
      <van-sidebar-item
        v-for="(item, i) in menuList"
        :key="i"
        :title="item.title"
        :to="item.page"
        @click="menuItemSelect"
      />
    </van-sidebar>
    <van-button hairline round @click="login_out">退出登录</van-button>
    <van-button hairline round @click="bind_wechat">绑定微信</van-button>
  </van-popup>
</template>
<script>
// @ is an alias to /src
import {Toast} from "vant";
import {bind_wechat, get_appid} from '../api/base'

export default {
  name: "Home",
  data() {
    return {
      //   menuShow: false,
      menuList: [
        { title: "报表统计", page: "finance_report" },
      ],
      activeKey: 0,
      phone:localStorage.getItem('phone'),
      isMenuOpen: false,
    };
  },
  computed: {
    menuShow:  {
      get(){
        return this.$store.getters.getMenuState;
      },
      set(){}
    },
    isClickHeader:  {
      get(){
        return this.$store.getters.getIsClickHeader;
      },
      set(){}
    },
  },
  mounted() {
    document.addEventListener("click", this.outClick);
  },
  destroyed() {
    document.removeEventListener("click", this.outClick);
  },
  methods: {
    showSideMenu() {
      this.$store.commit("setMenuState", true);
    },
    menuItemSelect(index) {
      this.$store.commit("setMenuState", false);
    },
    login_out(){
      localStorage.removeItem('token')
      Toast('退出成功');
      this.$store.commit("setMenuState", false);
      this.$router.push('/login')
    },
    bind_wechat(){
      bind_wechat({
        'openid':localStorage.getItem('openid')
      }).then((response)=>{
        if (response.data.code == 200) {
          Toast.success('绑定成功')
        } else {
          Toast.fail(response.data.msg);
        }
      });
    },
    outClick(e) {
      let sideMenu = this.$refs.sideMenu.$el;
      if (!sideMenu.contains(e.target) && this.menuShow) {
        if (this.isClickHeader) {
          this.$store.commit("setIsClickHeader", false);
        } else {
          this.menuItemSelect();
        }
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.side-menu-overlay {
  background-color: transparent;
}
.side-menu {
  background: #333;
  opacity: 0.8;
  color: #fff;
  width: 228px;
  height: calc(100% - 44px);
  bottom: 0;
  &.van-popup--left {
    top: calc(50% + 22px);
  }
  .side-menu-top {
    margin-bottom: 15px;
    .user-info {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 15px 25px;
      font-size: 17px;
      .van-image {
        width: 30px;
        height: 30px;
      }
    }
    .menu-title {
      padding: 15px 45px;
      font-size: 17px;
      text-align: left;
      & .van-divider {
        width: 69px;
        height: 3px;
        background: #586cb1;
        margin: 5px 0 0;
      }
    }
  }
  .van-sidebar {
    width: 100%;
    .van-sidebar-item {
      color: #fff;
      background-color: inherit;
      text-align: left;
      padding: 15px 45px;
      &.van-sidebar-item--select {
        background: #586cb1;
        &::before {
          display: none;
        }
      }
    }
  }
  .van-button {
    width: calc(100% - 50px);
    margin: 15px 25px;
    color: inherit;
    background-color: inherit;
    height: 32px;
  }
}
</style>