import request from '../utils/axios'

export function login(data) {
    return request({
        method: 'POST',
        url: '/login',
        data,
    })
}


export function info() {
    return request({
        headers:{
            Authorization: 'Bearer '+localStorage.getItem('token')
        },
        method: 'GET',
        url: '/info'
    })
}

export function bind_wechat(data) {
    return request({
        headers:{
            Authorization: 'Bearer '+localStorage.getItem('token')
        },
        method: 'POST',
        url: '/bind',
        data
    })
}

export function login_wechat(data) {
    return request({
        headers:{
            Authorization: 'Bearer '+localStorage.getItem('token')
        },
        method: 'POST',
        url: '/loginWechat',
        data
    })
}

export function get_appid() {
    return request({
        headers:{
            Authorization: 'Bearer '+localStorage.getItem('token')
        },
        method: 'GET',
        url: '/auth/getAppid'
    })
}

export function getActive() {
    return request({
        headers:{
            Authorization: 'Bearer '+localStorage.getItem('token')
        },
        method: 'GET',
        url: '/activeList'
    })
}

export function getBill(data) {
    return request({
        headers:{
            Authorization: 'Bearer '+localStorage.getItem('token')
        },
        method: 'POST',
        url: '/bill',
        data
    })
}

export function getIncomeExpend(data) {
    return request({
        headers:{
            Authorization: 'Bearer '+localStorage.getItem('token')
        },
        method: 'GET',
        url: '/check-bill-pie',
        params: data,
    })
}

export function getWriteOff(data) {
    return request({
        headers:{
            Authorization: 'Bearer '+localStorage.getItem('token')
        },
        method: 'GET',
        url: '/check-bill-bar',
        params: data,
    })
}